*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.m-24 {
  margin: 1.5rem;
}

.\!mb-0 {
  margin-bottom: 0 !important;
}

.\!mt-0 {
  margin-top: 0 !important;
}

.\!mt-64 {
  margin-top: 4rem !important;
}

.\!mt-\[55px\] {
  margin-top: 55px !important;
}

.\!mt-auto {
  margin-top: auto !important;
}

.mb-24 {
  margin-bottom: 1.5rem;
}

.mb-40 {
  margin-bottom: 2.5rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-24 {
  margin-right: 1.5rem;
}

.mt-24 {
  margin-top: 1.5rem;
}

.mt-48 {
  margin-top: 3rem;
}

.mt-\[-10px\] {
  margin-top: -10px;
}

.mt-\[300px\] {
  margin-top: 300px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.size-16 {
  width: 1rem;
  height: 1rem;
}

.h-20 {
  height: 1.25rem;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[45px\] {
  height: 45px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[436px\] {
  max-height: 436px;
}

.w-20 {
  width: 1.25rem;
}

.w-\[212px\] {
  width: 212px;
}

.w-\[56rem\] {
  width: 56rem;
}

.w-\[88px\] {
  width: 88px;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.\!max-w-\[400px\] {
  max-width: 400px !important;
}

.max-w-\[436px\] {
  max-width: 436px;
}

.max-w-\[750px\] {
  max-width: 750px;
}

.flex-shrink {
  flex-shrink: 1;
}

.border-collapse {
  border-collapse: collapse;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-center {
  place-items: center;
}

.\!items-start {
  align-items: flex-start !important;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-10 {
  gap: .625rem;
}

.gap-16 {
  gap: 1rem;
}

.gap-32 {
  gap: 2rem;
}

.gap-4 {
  gap: .25rem;
}

.gap-\[180px\] {
  gap: 180px;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-neutral-10 {
  --tw-border-opacity: 1;
  border-color: rgb(230 230 230 / var(--tw-border-opacity, 1));
}

.border-primary-pure {
  --tw-border-opacity: 1;
  border-color: rgb(48 84 255 / var(--tw-border-opacity, 1));
}

.\!border-l-primary-pure {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(48 84 255 / var(--tw-border-opacity, 1)) !important;
}

.bg-primary-pure\/10 {
  background-color: #3054ff1a;
}

.p-24 {
  padding: 1.5rem;
}

.px-24 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-4 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.py-12 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-8 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pl-12 {
  padding-left: .75rem;
}

.text-center {
  text-align: center;
}

.\!text-start {
  text-align: start !important;
}

.align-top {
  vertical-align: top;
}

.text-sm {
  font-size: .875rem;
  line-height: 150%;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.\!italic {
  font-style: italic !important;
}

.\!text-primary-pure {
  --tw-text-opacity: 1 !important;
  color: rgb(48 84 255 / var(--tw-text-opacity, 1)) !important;
}

.text-neutral-80 {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity, 1));
}

.text-primary-pure {
  --tw-text-opacity: 1;
  color: rgb(48 84 255 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.opacity-10 {
  opacity: .1;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-neutral-10:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(230 230 230 / var(--tw-bg-opacity, 1));
}

@media (width <= 1180px) {
  .md2\:m-0 {
    margin: 0;
  }
}

@media (width <= 639px) {
  .sm\:mt-\[0px\] {
    margin-top: 0;
  }

  .sm\:w-\[88px\] {
    width: 88px;
  }
}
/*# sourceMappingURL=tailwind.css.map */
